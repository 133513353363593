

















































































import mixins from 'vue-typed-mixins'
import Login from '@/calendesk/sections/section/static/login/mixins/Login'

export default mixins(Login).extend({
  name: 'Login'
})
